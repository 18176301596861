import {
  PROJECT_TYPE_ID_MARKETPLACESPACK,
  PROJECT_TYPE_ID_MARKETINGPACK,
  CLIENT_ID_ESIGN,
} from '../constants/enums';
import { toNumber } from '../utils/helper';

const createBudgetSlice = (set, get) => ({
  budget: null,
  setBudget: () => {
    const { task, timelog } = get();

    if (!task && task?.isNew) return;
    const { id, budget_time, progress, is_hours_bundle } = task;

    // Budget object
    let budget = {
      task_id: id,
      max: budget_time,
      current_used: progress,
      current_left: budget_time - progress,
      timelog_start_value: timelog?.time,
      has_budget: budget_time - progress > 0 || budget_time === 0,
    };

    if (is_hours_bundle) {
      const { client } = task;
      const { hours_bundle, hours_bundle_used } = client;

      budget = {
        ...budget,
        is_hours_bundle,
        hours_bundle,
        hours_bundle_used,
      };
    }

    set({ budget });
  },
  unsetBudget: () => set({ budget: null }),
  ignoreBudgetLimit: () => {
    const { task } = get();

    if (!task?.project || !task?.project?.project_type_id || !task?.client) {
      return false;
    }

    // If any of these IDs match, ignore budget limit
    return (
      toNumber(task.client.id) === CLIENT_ID_ESIGN ||
      toNumber(task.project.project_type_id) === PROJECT_TYPE_ID_MARKETPLACESPACK ||
      toNumber(task.project.project_type_id) === PROJECT_TYPE_ID_MARKETINGPACK
    );
  },
});

export default createBudgetSlice;
